import t from "typy";
import TractionTabs from "../../components/traction-engagements/tabs.vue";
import { months } from "../../utils/mockup";

export default {
    components: { TractionTabs },
    data() {
        return {
            data: {
                current_page: 1,
                per_page: 10,
                total: 10
            },
            error: '',
            months: [],
            years: [],
            filter: {
                year: 0,
                month: ('0' + (new Date().getUTCMonth() + 1)).slice(-2),
                per_page: 10,
                mode: 'd'
            },
            days: [],
            engagement_daily: {
                total_check_in: {},
                total_check_in_per_day: {},
                total_session: {}
            },
            engagement_snapshot: {
                total_pre_check_in_registered: {},
                total_check_in_registered: {},
                total_check_in_registered_by_tour_leader: {},
                total_check_in_registered_by_pms: {},
                total_check_in_registered_by_companion: {},
                total_check_in_registered_cancelled: {},

                total_check_out_registered: {},
                total_check_out_registered_by_pms: {},
                total_check_out_registered_by_tour_leader: {},
                total_check_out_registered_by_companion: {},
            },
            engagement_latest: {
                total_check_out_registered: {},
                total_pre_check_in_registered: {},
                total_check_in_registered: {},
                total_cancelled_registered: {},
            },
            totals: {
                check_in: 0,
                check_in_per_day: 0,
                session: 0,

                snapshot_total_pre_check_in_registered: 0,


                snapshot_total_check_in_registered: 0,
                snapshot_total_check_in_registered_by_tour_leader: 0,
                snapshot_total_check_in_registered_by_pms: 0,
                snapshot_total_check_in_registered_by_companion: 0,
                snapshot_total_check_in_registered_cancelled: 0,

                snapshot_total_check_out_registered: 0,
                snapshot_total_check_out_registered_by_pms: 0,
                snapshot_total_check_out_registered_by_tour_leader: 0,
                snapshot_total_check_out_registered_by_companion: 0,


                latest_total_pre_check_in_registered: 0,
                latest_total_check_in_registered: 0,
                latest_total_check_out_registered: 0,
                latest_total_cancelled_registered: 0,
            },
            config: {
                facility_name: 'All',
                checked_in_collapsed: 0,
                checked_out_collapsed: 0
            }
        }
    },
    async mounted() {
        await this.populate_years();
    },
    methods: {
        yearDisplay(year) {
            const display = year != 0 ? year : '';
            return display;
        },
        handleYear() {
            this.error = '';
        },
        async loadFiltered() {   
            if(this.filter['year'] !== 0) {
                this.error = '';
                this.$select_date();          
            } else {
                this.error = this.$t('error-messages.no-selected-year');
            }
        },
        async paginate(page) {

            var filter = {
                ...this.filter,
                page: page,
            }

            await this.list(filter)
        },
        async list(data) {

            const response = await this.$lionheart.get('/dashboard/traction/engagements', {params: data})
            this.data = response.data;

        },
        async monthly_engagement(data) {

            const response = await this.$lionheart.get('/dashboard/traction/monthly-engagement', {params: data})

            this.engagement_daily = response.data;
            
        },
        async snapshot_engagement(data) {

            const response = await this.$lionheart.get('/dashboard/traction/snapshot-engagement', {params: data})

            this.engagement_snapshot = response.data;

        },
        async latest_engagement(data) {

            const response = await this.$lionheart.get('/dashboard/traction/latest-engagement', {params: data})

            this.engagement_latest = response.data;

        },
        async populate_years() {
            var date = new Date();
            for (var i = 2019; i <= date.getFullYear(); i++) {
                this.years.push(i);
            }
        },
        async $select_date() {
            await this.populate_dates(this.filter.year,this.filter.month);
            await this.list(this.filter)
            await this.monthly_engagement(this.filter)
            await this.snapshot_engagement(this.filter);
            await this.latest_engagement(this.filter);
            await this.get_total_rates();
        },
        async $search_engagement(data,name) {
            await this.populate_dates(this.filter.year,this.filter.month);
            this.config.facility_name = name;
            await this.monthly_engagement({...this.filter, ...data})
            await this.snapshot_engagement({...this.filter, ...data})
            await this.latest_engagement({...this.filter, ...data})
            await this.get_total_rates();
        },
        async populate_dates(year, month) {
            this.days = [];
            month = parseInt(month) - 1;
            var date = new Date(year, month, 1)
            while (date.getMonth() == month) {
                this.days.push(('0' + (new Date(date).getDate())).slice(-2))
                date.setDate(date.getDate() + 1)
            }
        },
        async get_total_rates(){

         this.totals.total_check_in = await this.populate_total_rate(this.engagement_daily.total_check_in);
         this.totals.check_in_per_day = await this.populate_total_rate(this.engagement_daily.total_check_in_per_day);
         this.totals.session = await this.populate_total_rate(this.engagement_daily.total_session);

            this.totals.latest_total_pre_check_in_registered = await this.populate_total_rate(this.engagement_latest.total_pre_check_in_registered);
            this.totals.latest_total_check_in_registered = await this.populate_total_rate(this.engagement_latest.total_check_in_registered);
            this.totals.latest_total_check_out_registered = await this.populate_total_rate(this.engagement_latest.total_check_out_registered);
            this.totals.latest_total_cancelled_registered = await this.populate_total_rate(this.engagement_latest.total_cancelled_registered);

            this.totals.snapshot_total_pre_check_in_registered = await this.populate_total_rate(this.engagement_snapshot.total_pre_check_in_registered);
            this.totals.snapshot_total_check_in_registered = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered);
            this.totals.snapshot_total_check_in_registered_by_tour_leader = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_tour_leader);
            this.totals.snapshot_total_check_in_registered_by_pms = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_pms);
            this.totals.snapshot_total_check_in_registered_by_companion = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_by_companion);
            this.totals.snapshot_total_check_in_registered_cancelled = await this.populate_total_rate(this.engagement_snapshot.total_check_in_registered_cancelled);

            this.totals.snapshot_total_check_out_registered = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered);
            this.totals.snapshot_total_check_out_registered_by_pms = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_pms);
            this.totals.snapshot_total_check_out_registered_by_tour_leader = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_tour_leader);
            this.totals.snapshot_total_check_out_registered_by_companion = await this.populate_total_rate(this.engagement_snapshot.total_check_out_registered_by_companion);
        },
        async populate_total_rate(data){

            var total = 0;
            for (var key in data) {
              total = data[key].total + total;
            }

            return total;
        }
    }
}
